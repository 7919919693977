<template>
  <div>
    <div class="logo-container p-4">
      <img alt="Company Logo" src="./../../assets/colgate-logo-2021.svg" />
    </div>
  </div>
  <div class="menu-container">
    <div id="docs-sidebar"
      class="hs-overlay hs-overlay-open:translate-x-0 transition-all duration-300 transform left-0 z-[60] w-64 border-r pt-7 pb-10 overflow-y-auto scrollbar-y block translate-x-0 right-auto bottom-0 scrollbar-y bg-gray-800 border-gray-700">
      <div class="px-6">
        <a aria-label="Brand" class="flex-none text-xl font-semibold text-white" href="javascript:">Colgate</a>
      </div>
      <nav class="hs-accordion-group p-6 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
        <ul class="space-y-1.5">
          <li>
            <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-md hover:bg-gray-100 bg-gray-900 hover:text-black"
              href="javascript:" @click="changeRoute('Dashboard')">
              <svg class="w-3.5 h-3.5" fill="currentColor" height="16" viewBox="0 0 16 16" width="16"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                  fill-rule="evenodd" />
                <path
                  d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                  fill-rule="evenodd" />
              </svg>
              Dashboard
            </a>
          </li>

          <li v-for="item in menuWithSubmenu" :id="item.id" :key="item.id" class="hs-accordion cursor-pointer">
            <a :href="item.href" v-if="item.permission"
              class="hs-accordion-toggle flex items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:hover:bg-transparent text-sm rounded-md bg-gray-800 hover:bg-gray-900 text-slate-400 hover:text-slate-300 hs-accordion-active:text-white"
              @click="item.action">
              <div v-html="item.icon"></div>
              {{ item.title }}
              <svg v-if="item.submenu"
                class="hs-accordion-active:block ml-auto hidden w-3 h-3 text-white group-hover:text-gray-500"
                fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                  stroke="currentColor" stroke-linecap="round" stroke-width="2"></path>
              </svg>

              <svg v-if="item.submenu"
                class="hs-accordion-active:hidden ml-auto block w-3 h-3 text-white group-hover:text-gray-500"
                fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                  stroke="currentColor" stroke-linecap="round" stroke-width="2"></path>
              </svg>
            </a>

            <div v-if="item.submenu" :id="item.id"
              class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden">
              <ul class="hs-accordion-group pl-3 pt-2" data-hs-accordion-always-open>
                <li v-for="(subitem, index) in item.submenu" id="users-accordion-sub-1" :key="index"
                  class="hs-accordion">
                  <a :class="{ 'hs-accordion-toggle': subitem.links }" :href="subitem.href" v-if="subitem.permission"
                    class="flex items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:hover:bg-transparent text-sm rounded-md bg-gray-800 hover:bg-gray-900 text-slate-400 hover:text-slate-300 hs-accordion-active:text-white"
                    @click="subitem.action">
                    {{ subitem.title }}
                    <svg v-if="subitem.links"
                      class="hs-accordion-active:block ml-auto hidden w-3 h-3 group-hover:text-gray-500 text-gray-400"
                      fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                        stroke="currentColor" stroke-linecap="round" stroke-width="2"></path>
                    </svg>

                    <svg v-if="subitem.links"
                      class="hs-accordion-active:hidden ml-auto block w-3 h-3 group-hover:text-gray-500 text-gray-400"
                      fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                        stroke="currentColor" stroke-linecap="round" stroke-width="2"></path>
                    </svg>
                  </a>

                  <div id="users-accordion-sub-1"
                    class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden">
                    <ul class="pt-2 pl-2">
                      <li v-for="item in subitem.links" :key="item.title">
                        <a :href="item.href" v-if="item.permission"
                          class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm rounded-md hover:bg-gray-600 bg-gray-800 text-slate-400 hover:text-slate-300"
                          @click="item.action">
                          {{ item.title }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthenticationStore, useGroup, usePermissions } from '@/store'

const router = useRouter()
const authentication = useAuthenticationStore()
const group = useGroup()
const permissions = usePermissions()

const menuWithSubmenu = ref()

function checkPermission(resource, action) {
  const permissionList = permissions.getPermissions

  const hasRootAccess = permissions.getPermissions.some(permission =>
    permission.roles?.includes('root')
  )

  if (hasRootAccess) return true

  return permissionList.some(permission => {
    const permissionKeys = Object.keys(permission)
    return (
      permissionKeys.includes(resource) && permission[resource].includes(action)
    )
  })
}

const route = useRouter()

const changeRoute = to => {
  try {
    route.push({ name: to })
  } catch (e) {
    window.location.href = '/working-in-progress'
  }
}

menuWithSubmenu.value = [
  {
    id: 'espacos_confinados',
    title: 'Espaços Confinados',
    permission: checkPermission('espacos_confinados', 'read'),
    action: null,
    icon:
      '<svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-4">\n' +
      '<path clip-rule="evenodd" fill-rule="evenodd" d="M10.362 1.093a.75.75 0 00-.724 0L2.523 5.018 10 9.143l7.477-4.125-7.115-3.925zM18 6.443l-7.25 4v8.25l6.862-3.786A.75.75 0 0018 14.25V6.443zm-8.75 12.25v-8.25l-7.25-4v7.807a.75.75 0 00.388.657l6.862 3.786z"></path>\n' +
      '</svg>\n',
    submenu: [
      {
        title: 'Listar Espaços',
        permission: checkPermission('espacos_confinados', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('ListEspacoConfinado')
        }
      },
      {
        title: 'Categorias',
        permission: checkPermission('categorias', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('Categorias')
        }
      },
      {
        title: 'Localizações',
        permission: checkPermission('localizacoes', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('Localizacoes')
        }
      },
      {
        title: 'Tipos de Espaço',
        permission: checkPermission('tipos_espacos', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('TiposEspacos')
        }
      }
    ]
  },
  {
    id: 'usuarios',
    title: 'Usuários',
    permission: checkPermission('usuarios', 'read'),
    action: null,
    icon:
      '<svg fill="currentColor" class="w-4 h-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">\n' +
      '  <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z"></path>\n' +
      '</svg>\n',
    submenu: [
      {
        title: 'Listar Usuários',
        permission: checkPermission('usuarios', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('UsersList')
        }
      }
    ]
  },
  {
    id: 'configuracoes',
    title: 'Configurações',
    permission: checkPermission('configuracoes', 'read'),
    action: null,
    icon:
      '<svg fill="currentColor" class="w-4 h-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">\n' +
      '  <path d="M13.024 9.25c.47 0 .827-.433.637-.863a4 4 0 00-4.094-2.364c-.468.05-.665.576-.43.984l1.08 1.868a.75.75 0 00.649.375h2.158zM7.84 7.758c-.236-.408-.79-.5-1.068-.12A3.982 3.982 0 006 10c0 .884.287 1.7.772 2.363.278.38.832.287 1.068-.12l1.078-1.868a.75.75 0 000-.75L7.839 7.758zM9.138 12.993c-.235.408-.039.934.43.984a4 4 0 004.094-2.364c.19-.43-.168-.863-.638-.863h-2.158a.75.75 0 00-.65.375l-1.078 1.868z"></path>\n' +
      '  <path clip-rule="evenodd" fill-rule="evenodd" d="M14.13 4.347l.644-1.117a.75.75 0 00-1.299-.75l-.644 1.116a6.954 6.954 0 00-2.081-.556V1.75a.75.75 0 00-1.5 0v1.29a6.954 6.954 0 00-2.081.556L6.525 2.48a.75.75 0 10-1.3.75l.645 1.117A7.04 7.04 0 004.347 5.87L3.23 5.225a.75.75 0 10-.75 1.3l1.116.644A6.954 6.954 0 003.04 9.25H1.75a.75.75 0 000 1.5h1.29c.078.733.27 1.433.556 2.081l-1.116.645a.75.75 0 10.75 1.298l1.117-.644a7.04 7.04 0 001.523 1.523l-.645 1.117a.75.75 0 101.3.75l.644-1.116a6.954 6.954 0 002.081.556v1.29a.75.75 0 001.5 0v-1.29a6.954 6.954 0 002.081-.556l.645 1.116a.75.75 0 001.299-.75l-.645-1.117a7.042 7.042 0 001.523-1.523l1.117.644a.75.75 0 00.75-1.298l-1.116-.645a6.954 6.954 0 00.556-2.081h1.29a.75.75 0 000-1.5h-1.29a6.954 6.954 0 00-.556-2.081l1.116-.644a.75.75 0 00-.75-1.3l-1.117.645a7.04 7.04 0 00-1.524-1.523zM10 4.5a5.475 5.475 0 00-2.781.754A5.527 5.527 0 005.22 7.277 5.475 5.475 0 004.5 10a5.475 5.475 0 00.752 2.777 5.527 5.527 0 002.028 2.004c.802.458 1.73.719 2.72.719a5.474 5.474 0 002.78-.753 5.527 5.527 0 002.001-2.027c.458-.802.719-1.73.719-2.72a5.475 5.475 0 00-.753-2.78 5.528 5.528 0 00-2.028-2.002A5.475 5.475 0 0010 4.5z"></path>\n' +
      '</svg>\n',
    submenu: [
      {
        title: 'Setup Sistema',
        permission: checkPermission('setup_sistema', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('SetupSistema')
        }
      },
      {
        title: 'Setup Unidade',
        permission: checkPermission('setup_unidade', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('SetupUnidade')
        }
      },
      {
        title: 'Posição de Acesso',
        permission: checkPermission('posicao_acesso', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('PosicaoAcesso')
        }
      },
      {
        title: 'Pontos de Ancoragem',
        permission: checkPermission('pontos_ancoragem', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('PontosAncoragem')
        }
      },
      {
        title: 'Motivos PET',
        permission: checkPermission('motivos_pet', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('MotivosPet')
        }
      },
      {
        title: 'Configurações de e-mail',
        permission: checkPermission('configuracoes_email', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('ConfiguracoesEmail')
        }
      },
      {
        title: 'Templates de e-email',
        permission: checkPermission('configuracoes_email', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('TemplatesEmail')
        }
      },
      {
        title: 'Configurações de SMS',
        permission: checkPermission('configuracoes_sms', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('ConfiguracoesSms')
        }
      },
      {
        title: 'Cadastro de checklist',
        permission: checkPermission('cadastro_checklist', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('CadastroChecklist')
        }
      }
    ]
  },
  {
    id: 'emergencia',
    title: 'Emergências',
    permission: checkPermission('emergencia', 'read'),
    action: null,
    icon:
      '<svg fill="currentColor" class="w-4 h-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">\n' +
      '  <path d="M9.653 16.915l-.005-.003-.019-.01a20.759 20.759 0 01-1.162-.682 22.045 22.045 0 01-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 018-2.828A4.5 4.5 0 0118 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 01-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 01-.69.001l-.002-.001z"></path>\n' +
      '</svg>\n',
    submenu: [
      {
        id: 'cadastros-emergencia',
        title: 'Cadastros',
        permission: checkPermission('cadastros_emergencia', 'read'),
        action: null,
        links: [
          {
            title: 'Equipamento Mínimo',
            permission: checkPermission('equipamento_minimo', 'read'),
            href: 'javascript:',
            action: () => {
              changeRoute('EquipamentoMinimo')
            }
          },
          {
            title: 'Procedimento Padrão',
            permission: checkPermission('procedimento_padrao', 'read'),
            href: 'javascript:',
            action: () => {
              changeRoute('ProcedimentoPadrao')
            }
          },
          {
            title: 'Acionamento Resgate',
            permission: checkPermission('acionamento_resgate', 'read'),
            href: 'javascript:',
            action: () => {
              changeRoute('AcionamentoResgate')
            }
          }
        ]
      }
    ]
  },
  {
    id: 'permissoes',
    title: 'Permissões',
    permission: checkPermission('permissoes', 'read'),
    action: null,
    icon:
      '<svg fill="currentColor" class="w-4 h-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">\n' +
      '  <path clip-rule="evenodd" fill-rule="evenodd" d="M9.661 2.237a.531.531 0 01.678 0 11.947 11.947 0 007.078 2.749.5.5 0 01.479.425c.069.52.104 1.05.104 1.59 0 5.162-3.26 9.563-7.834 11.256a.48.48 0 01-.332 0C5.26 16.564 2 12.163 2 7c0-.538.035-1.069.104-1.589a.5.5 0 01.48-.425 11.947 11.947 0 007.077-2.75zm4.196 5.954a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"></path>\n' +
      '</svg>',
    submenu: [
      {
        title: 'PET',
        permission: checkPermission('emissao_pet', 'read'),
        href: 'javascript:',
        action: null,
        links: [
          {
            title: 'Dashboard',
            href: 'javascript:',
            permission: checkPermission('dashboard_pet', 'read'),
            action: () => {
              changeRoute('DashboardPet')
            }
          },
          {
            title: 'Incluir',
            href: 'javascript:',
            permission: checkPermission('incluir_pet', 'read'),
            action: () => {
              changeRoute('InclusaoPermissaoEntradaTrabalho')
            }
          }
        ]
      },
      {
        title: 'PTS',
        permission: checkPermission('emissao_pts', 'read'),
        href: 'javascript:',
        action: null,
        links: [
          {
            title: 'Incluir',
            permission: checkPermission('emissao_pts', 'create'),
            href: 'javascript:',
            action: () => {
              changeRoute('InclusaoPermissaoTrabalho')
            }
          },
          {
            title: 'Alterar',
            permission: checkPermission('emissao_pts', 'update'),
            href: 'javascript:',
            action: () => {
              changeRoute('AlteracaoPermissaoTrabalho')
            }
          },
          {
            title: 'Remover',
            permission: checkPermission('emissao_pts', 'delete'),
            href: 'javascript:',
            action: () => {
              changeRoute('RemocaoPermissaoTrabalho')
            }
          }
        ]
      },

      {
        title: 'Relatórios',
        permission: checkPermission('emissao_pet', 'read'),
        href: 'javascript:',
        action: null,
        links: [
          {
            title: 'Dashboard',
            href: 'javascript:',
            permission: checkPermission('dashboard_pet', 'read'),
            action: () => {
              changeRoute('DashboardRelatorios')
            }
          },
        ]
      },
    ]
  },
  {
    title: 'Colaboradores',
    permission: checkPermission('colaboradores', 'read'),
    href: 'javascript:',
    action: null,

    icon:
      '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">' +
      '<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />' +
      '</svg>\n',
    submenu: [
      {
        title: 'Equipe Colgate',
        permission: checkPermission('colaboradores', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('ColaboradoresColgate')
        }
      },
      {
        title: 'Prestadores',
        permission: checkPermission('colaboradores', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('ColaboradoresPrestadores')
        }
      }
    ]
  },
  {
    title: 'Fornecedores',
    permission: checkPermission('empresas_terceiras', 'read'),
    href: 'javascript:',
    action: null,
    icon:
      '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">' +
      '<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />' +
      '</svg>\n',
    submenu: [
      {
        title: 'Pre-Qualificação',
        permission: checkPermission('empresas_terceiras', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('CadastroFornecedor')
        }
      },
      {
        title: 'Status Documentação Prestadores de Serviço',
        permission: checkPermission('empresas_terceiras', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('ConvitesEnviados')
        }
      },
      {
        title: 'Portaria',
        permission: checkPermission('empresas_terceiras', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('Portaria')
        }
      },
      {
        title: 'Prestador',
        permission: checkPermission('empresas_terceiras', 'read'),
        href: 'javascript:',
        action: () => {
          changeRoute('CadastroPrestadores')
        }
      }
    ]
  },
  {
    id: 'sair',
    title: 'Sair',
    permission: true,
    icon:
      '<svg fill="currentColor" class="w-4 h-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">\n' +
      '  <path clip-rule="evenodd" fill-rule="evenodd" d="M3 4.25A2.25 2.25 0 015.25 2h5.5A2.25 2.25 0 0113 4.25v2a.75.75 0 01-1.5 0v-2a.75.75 0 00-.75-.75h-5.5a.75.75 0 00-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75v-2a.75.75 0 011.5 0v2A2.25 2.25 0 0110.75 18h-5.5A2.25 2.25 0 013 15.75V4.25z"></path>\n' +
      '  <path clip-rule="evenodd" fill-rule="evenodd" d="M6 10a.75.75 0 01.75-.75h9.546l-1.048-.943a.75.75 0 111.004-1.114l2.5 2.25a.75.75 0 010 1.114l-2.5 2.25a.75.75 0 11-1.004-1.114l1.048-.943H6.75A.75.75 0 016 10z"></path>\n' +
      '</svg>\n',
    action: () => {
      try {
        authentication.logout()
        group.setClearAll()
        router.push('/login')
      } catch (error) {
        console.log(error)
      }
    }
  }
]
</script>

<style scoped>
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}
</style>
